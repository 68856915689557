import { apiServices } from '../../../services/api.service';
import { errorHandler } from '../../../services/_helper';

export const getAllUserCollection = async ({ commit }, { limit, offset, keyword, status }) => {
	commit("SET_DATA_LOADER", true);
	await apiServices.getAllUserCollection(limit, offset, keyword, status).then(
		(response) => {
			commit("SET_DATA_LOADER", false);
			commit('SET_LOAD_MORE', response.data.total > offset + limit);
			if (+offset > 0) {
				commit('SET_POSTS2', response.data.result);
			} else {
				commit('SET_POSTS1', response.data);
			}
		},
		(error) => {
			commit("SET_DATA_LOADER", false);
			errorHandler(error.response);
		}
	);
};

