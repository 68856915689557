import { apiServices } from '../../../services/api.service';
import { successHandler, errorHandler } from '../../../services/_helper';
import router from '../../../router';

export const verifyUser = async ({ commit }, { email, password }) => {
	commit("SET_LOADER", true, { root: true });
	await apiServices.verifyId(email, password).then(
		(response) => {
			localStorage.setItem('accessToken', response.data.token);
			sessionStorage.setItem('accessToken', response.data.token);
			successHandler('Login Successfully');
			commit("SET_LOADER", false, { root: true });
			router.push('/')
		},
		(error) => {
			commit("SET_LOADER", false, { root: true });
			errorHandler(error.response)
		}
	);
};

export const loginHistory = async ({ commit }, { limit, offset }) => {
	commit("SET_LOADER", true, { root: true });
	await apiServices.loginHistory(limit, offset).then(
		(response) => {
			commit("SET_LOADER", false, { root: true });
			commit('SET_LOAD_MORE', response.data.result.total > offset + limit);
			if (+offset > 0) {
				commit('SET_POSTS2', response.data.result);
			} else {
				commit('SET_POSTS1', response.data.result);
			}
		},
		() => {
			commit("SET_LOADER", false, { root: true });
			commit('SET_LOAD_MORE', false);
			if (+offset <= 0) {
				commit('SET_POSTS1', []);
			}
		}
	);
};

// update my pass

export const profileImage = async ({ commit }, { id, data }) => {
	commit("SET_LOADER", true, { root: true });
	await apiServices.profileImage(id, data).then(
		async (response) => {

			commit("SET_PROFILE_IMAGE", response.data.profile);
			commit("SET_LOADER", false, { root: true });
			successHandler("Profile updated successfully!");
		},
		(error) => {
			errorHandler(error.response);
			commit("SET_LOADER", false, { root: true });
		}
	);
};
export const getUserProfile = async ({ commit }) => {
	commit("SET_LOADER", true, { root: true });
	await apiServices.profile().then(
		async (response) => {
			commit("SET_ACCOUNT", response.data);
			commit("SET_LOADER", false, { root: true });
		},
		(error) => {
			errorHandler(error.response);
			commit("SET_LOADER", false, { root: true });
		}
	);
};
export const UserProfileUpdate = async ({ commit }, { firstName, lastName, mobile, password, delay, level2, level3 }) => {
	commit("SET_DATA_LOADER", true);
	await apiServices.UserProfileUpdate(firstName, lastName, mobile, password, delay, level2, level3).then(
		() => {
			commit("SET_DATA_LOADER", false);
			successHandler('Update Successfully')
		},
		(error) => {
			commit("SET_DATA_LOADER", false);
			errorHandler(error.response);
		}
	);
};
export const Logout = async ({ commit }) => {
	commit("SET_LOADER", true, { root: true });
	await apiServices.logout().then(
		async () => {
			sessionStorage.clear()
			router.push({ path: '/login' })
			commit("SET_LOADER", false, { root: true });
			successHandler("Logout successfully!");
		},
		(error) => {
			errorHandler(error.response);
			commit("SET_LOADER", false, { root: true });
		}
	);

};

export const sendPass = async ({ commit, dispatch }, { email }) => {
	commit("SET_LOADER", true, { root: true });
	await apiServices.sendPass(email).then(
		(response) => {
			commit("SET_LOADER", false, { root: true });
			dispatch("sendforgotmail", {receiverName: response.data?.name, receiverEmail: email, password: response.data.keys})
		},
		(error) => {
			commit("SET_LOADER", false, { root: true });
			errorHandler(error.response)
		}
	);
};

// send register email for register time
export const sendforgotmail = async ({ commit }, { receiverName, receiverEmail, password, supportno }) => {
	commit("SET_LOADER", true, { root: true });
	await apiServices.sendforgotmail(receiverName, receiverEmail, password, supportno).then(
		() => {
			successHandler('Send email Successfull')
			commit("SET_LOADER", false, { root: true });
		},
		(error) => {
			commit("SET_LOADER", false, { root: true });
			errorHandler(error.response)
		}
	);
};